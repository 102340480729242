<template>
    <div class="apps">
       <div class="reheader">
       <nav v-for="(tabBar, index) in data" :key="index" class="rediv">
          <div
            class="a"
            :class="{ active: tabIndex == tabBar.id }"
            @click="toggleTab(tabBar.id)"
          >
            {{ tabBar.name }}
          </div>
        </nav>

    </div>
    <div  v-show="tabIndex==0">
     <div class="commiddle"  v-for="(item,index) in OptimumList" :key='index'>
        <div>
           <viewer>  <img  class="comimage" :src="item.image"></viewer>
        </div>
        <div class="conmmre">
            <div>
                  <router-link tag="div" :to="{path:'/GoodsDetial',query:{id:item.goodsId}}">
                <span class="comtitle">{{item.goodsName}}</span>
                </router-link
                >
            </div>
            <div class="comcontent">

            </div>
            <div class="comaa">
                <router-link :to="{path:'/StoreHome',query:{id:item.companyId,name:item.companyName}}" tag="div" class="righta" >
                  <div class="conts">{{item.companyName}}></div>
                </router-link>


            </div>
        </div>
        <div>
                <span class="comtime">{{$moment(item.creatTime ).format('YYYY年MM月DD日 HH时mm分ss秒') }}</span>
                <div class="comm" v-if="item.goodsPrice == 0">
                  <span style="color:#d0111a">￥</span><span class="commoney">电议</span>
                <br/>

              </div>
          <div class="comm" v-else>
            <span style="color:#d0111a">￥</span><span class="commoney">{{item.goodsPrice}}</span><span style="color:rgba(0, 0, 0, 0.56)">/吨</span>
            <br/>

          </div>
              <!-- <div><span class="nowmoney">现价：￥5600/吨</span></div> -->
        </div>

    </div>

</div>
<div  v-show="tabIndex==1">
    <div class="commiddle" v-for="(item,index) in informationList" :key='index'>

        <div class="conmmre">
            <div>
                <div class="relediv" v-show="item.intention==0">求购</div>
                <div class="relediv2" v-show="item.intention==1">出售</div>
            </div>
             <router-link :to="{path:'/exchangeDetial',query:{id
                :item.id}}" tag="div" class="contente">
            <div class="relecontent">
               {{item.title}}
            </div>
             </router-link>
            <div class="relebottomm">
              <div>
                  {{item.describe}}
              </div>
            </div>
        </div>

    </div>

</div>


      <!-- <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-size="pageSize"
      layout="  prev, pager, next"
      :total="totalCount"
    >
    </el-pagination> -->
     <Pagination class="el-pagination " :num="totalCount" ref="changepage" :limit="pageSize" :totalPageCount="totalPageCount"></Pagination>

    </div>
</template>
<script>
import goodsApi from '../../api/goodsApi'
import Pagination from "@/components/prePage/pagination.vue";
export default {
    data() {
        return {
                data:[
                {name:'商品',id:0},
                {name:'信息',id:1},
            ],
            tabIndex:0,
            selltype:0,
     pageNum: 1,
      pageSize:5,
    //   总数
      totalCount:0,
      totalPageCount:0,
      OptimumList:[

      ],
      informationList:[],
        }
    },
      components: {

    Pagination
  },
    created(){
        this.getCollectRecordList()
    },
    methods: {
           change(even){
 this.pageNum = even
  if( this.tabIndex==0){
                this.getCollectRecordList()
            }
            else{
       this.getCollectRecordInformation()
            }
    },
        toggleTab(index){
            this.tabIndex = index
        //    this.change(1)

        this.$refs.changepage.pageOffset(1)
        //    this.getCollectRecordList()
        this.change(1)
        },
         // 获取商品收藏类表
        getCollectRecordList(){
            goodsApi.getCollectRecordList({page:this.pageNum,size:this.pageSize},res=>{

                if(res.data.code==200){
                     this.OptimumList = res.data.result.data;

                    this.totalCount = res.data.result.totalCount;
                    this.totalPageCount = res.data.result.totalPageCount;
                }
            })
        },
        // 获取信息收藏类表
           getCollectRecordInformation(){
            goodsApi.getCollectRecordInformation({page:this.pageNum,size:this.pageSize},res=>{

                if(res.data.code==200){
                     this.informationList = res.data.result.data;

                    this.totalCount = res.data.result.totalCount;
                    this.totalPageCount = res.data.result.totalPageCount;
                }
            })
        },
         //监听pagesize改变事件
    handleSizeChange(newSize) {
      this.pageSize = newSize;

    this.getCollectRecordList()
    },
    //监听pageNum改变事件
    handleCurrentChange(newPage) {
      this.pageNum = newPage;

    this.getCollectRecordList()
    },
    },
}
</script>

<style lang='scss' scope>
.el-pagination{
    // border: 1px solid red;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
}
  .righta{
    text-decoration: none;
  }
.apps{
width:100%;
// width: 925px;
// margin-left: 80px;
min-width:790px;
// border: 1px solid red;
}
.reheader{
display: flex;
flex-direction: row;
margin-left: 250px;
}
.rediv{
display: flex;
flex-direction: row;
width: 100px;
height: 29px;
font-size: 18px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #868686;
line-height: 27px;
cursor: pointer;
margin-left: 100px;
}
.reheader nav:nth-of-type(1){
    border-right: 2px solid #979797 ;
    padding-right: 20px;
}

.active{
color: #E94F55;
}
.commiddle{
    margin-top: 10px;
    padding: 15px;
    display: flex;
    flex-direction: row;
    width:100%;
    background: #FFFFFF;
    border-radius: 10px;
    box-sizing: border-box;


.conmmre{
    margin-left: 15px;
    width: 58%;
    height: 100px !important;
}
.comimage{
    background: #979797;
    width: 120px;
    height: 129px;
}
.comspan{
    height: 20px;
    margin-top: -20px;
    text-align: center;
    background:rgba(0, 0, 0, 0.5);
    color: #fff;
}

.comtitle{
cursor: pointer;
// height: 37px;
font-size: 20px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 550;
color: #000000;
}
.comtime{
height: 22px;
font-size: 12px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: rgba(0, 0, 0, 0.56);
line-height: 22px;
}
.comcontent{
// height: 46px;
font-size: 14px;
font-weight: 400;
color: rgba(0, 0, 0, 0.56);
line-height: 28px
}
.conts{
    cursor: pointer;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: rgba(0, 0, 0, 0.87);
margin-top: 80px;
}
.comadd{
    width: 160px;
font-size: 12px;
font-weight: 400;
color: rgba(0, 0, 0, 0.56);
line-height: 25px;
}
.comm{
    font-size: 14px;
    width: 100px;
    margin-top: 50px;
}
.commoney{
    width: 72px;
height: 42px;
font-size: 24px;
font-weight: 600;
color: #D0111A;
line-height: 42px;
}
.nowmoney{
    height: 22px;
font-size: 10px;
font-weight: 400;
color: rgba(0, 0, 0, 0.56);
}

.relediv{
    width: 71px;
height: 31px;
background: #0171E3;
border-radius: 0px 10px 0px 10px;
font-size: 16px;
color: white;
text-align: center;
line-height: 30px;
margin-left: 690px;

}
.relediv2{
    width: 71px;
height: 31px;
background: #E94F55;
border-radius: 0px 10px 0px 10px;
font-size: 16px;
color: white;
text-align: center;
line-height: 30px;
margin-left: 690px;
}
.relecontent{
    cursor: pointer;
    width: 752px;
height: 40px;
font-size: 20px;
font-weight: 600;
color: rgba(0, 0, 0, 0.87);
line-height: 40px;
}
.relebottomm{
    margin-top: 10px;
    width: 752px;
height: 60px;
font-size: 14px;
font-weight: 400;
color: rgba(0, 0, 0, 0.56);
line-height: 20px;
}
}

</style>
